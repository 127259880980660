@import "global.css";
.btn{
    border: none;
    display: grid;
    justify-items: center;
    cursor: pointer;

    &:hover {
        box-shadow: 4px 4px 3px 0 rgba(0, 0, 0, 0.5);
    }
}
